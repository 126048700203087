<template>
  <v-main>
    <v-container fluid>
      <div
        class="text-h4 mt-8 mb-5 font-weight-bold"
        v-text="$t('office.navigation.planning.gantt')"
      ></div>
      <v-row>
        <v-col cols="12">
          <v-card class="px-3" :loading="loading">
            <calendar-legend :periods="periods" :events="events" />
            <calendar-info v-model="showInfo" />
            <div
              id="container"
              class="ganttContainer"
              ref="ganttContainer"
              @mousedown="mouseDragHandler($event)"
            >
              <div id="dates" class="ganttContainer" v-bind:style="columns">
                <div
                  class="block ganttBlockColor"
                  v-for="year in uniqueYears"
                  :key="year"
                  v-bind:style="yearColumns(year)"
                >
                  <span>{{ year }}</span>
                </div>
                <div
                  class="block ganttBlockColor"
                  v-for="month in uniqueMonths"
                  :key="month"
                  v-bind:style="monthColumns(month)"
                >
                  {{ getMonthName(month) }}
                </div>
                <div class="block ganttBlockColor" v-for="(week, i) in weeks" :key="i">
                  {{ week.number }}
                </div>
              </div>
              <div id="column-headers" class="ganttBlockColor">
                <v-chip class="ml-7 mt-8" v-if="projects.length" label disabled outlined>
                  <v-icon left>mdi-briefcase</v-icon>
                  <span class="mr-1">{{ projects.length }}</span>
                  <span>{{ $t('office.navigation.root.projects') }}</span>
                </v-chip>
              </div>
              <CalendarHeaders
                :projects="projects"
                :editablePeriods.sync="editablePeriods"
                :editableEvents.sync="editableEvents"
                :projectPeriods="projectPeriods"
                :headers="['code', 'region']"
                @updateProject="projectId => updateProject(projectId)"
                @handleEditablePeriods="arg => handleEditablePeriods(arg)"
              />
              <div ref="periods" id="periods" :style="table">
                <div class="todayIndicatorLine" :style="todayIndicatorLine"></div>
                <div
                  v-for="period in projectPeriods"
                  :key="period.id"
                  :style="[plotPeriod(period)]"
                  class="periods"
                  :class="[
                    `period-color-${period.type}`,
                    `period-status-${period.status}-${period.type}`,
                  ]"
                ></div>
                <div
                  v-for="(event, index) in projectEvents"
                  :key="index"
                  :style="[plotEvent(event)]"
                  class="events d-flex justify-center align-center"
                >
                  <v-icon color="#00838f">{{ event.icon }}</v-icon>
                </div>
                <div
                  v-if="editablePeriods.length"
                  class="period-editor-area ganttContainer"
                  :style="[periodEditorArea]"
                >
                  <event-editor
                    v-for="(event, i) in editableEvents"
                    :container="container"
                    :editableEvents.sync="editableEvents"
                    :weeks="weeks"
                    :key="event.id"
                    :event="event"
                    :index="i"
                  />
                  <period-editor
                    v-for="(period, i) in editablePeriods"
                    :container="container"
                    :editablePeriods.sync="editablePeriods"
                    :weeks="weeks"
                    :key="period.id"
                    :period="period"
                    :index="i"
                  />
                </div>
              </div>
              <div :class="{ periodsOverlay: editablePeriods.length }"></div>
            </div>
            <div class="d-flex justify-end align-center py-2">
              <div class="status not-started mr-2"></div>
              <span>{{ $t('office.labels.status.notStarted') }}</span>
              <div class="status started mx-2"></div>
              <span>{{ $t('office.labels.status.started') }}</span>
              <div class="status finished mx-2"></div>
              <span class="mr-2">{{ $t('office.labels.status.finished') }}</span>
              <v-btn icon @click="showInfo = !showInfo">
                <v-icon size="25" color="blue darken-2">
                  mdi-information-outline
                </v-icon>
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import moment from 'moment';
import _ from 'underscore';
import filters from '@/apps/office/components/table/shared/items/filters';

import CalendarInfo from '@/apps/office/pages/planning/containers/CalendarInfo';
import PeriodEditor from '@/apps/office/components/calendar/PeriodEditor';
import CalendarHeaders from '@/apps/office/pages/planning/containers/CalendarHeaders';
import CalendarLegend from '@/apps/office/pages/planning/containers/CalendarLegend';
import EventEditor from '@/apps/office/components/calendar/EventEditor';

export default {
  components: {
    CalendarInfo,
    CalendarHeaders,
    PeriodEditor,
    CalendarLegend,
    EventEditor,
  },

  data() {
    return {
      projects: [],
      weeks: [],
      range: this.initialRange(),
      periods: [
        { type: 'screening', color: '#80cbc4' },
        { type: 'conversion', color: '#ce93d8' },
        { type: 'planning', color: '#e6b635' },
        { type: 'installation', color: '#42c5fe' },
      ],
      events: [
        { type: 'screeningStartedAt', icon: 'mdi-clock-start', color: '#00838F' },
        { type: 'webinarAt', icon: 'mdi-webrtc', color: '#00838F' },
        { type: 'preAnnouncementAt', icon: 'mdi-rocket-launch-outline', color: '#00838F' },
        { type: 'contractsSentAt', icon: 'mdi-email-fast-outline', color: '#00838F' },
      ],
      loading: false,
      showInfo: false,
      pos: { top: 0, left: 0, x: 0, y: 0 },
      editablePeriods: [],
      editableEvents: [],
      container: {},
      item: [],
      filters: filters,
    };
  },

  async mounted() {
    this.container = this.$refs.ganttContainer;

    await this.search();
    this.scrollToCurrentWeek();
  },

  watch: {
    async lastSearch() {
      await this.search();
      this.scrollToCurrentWeek();
    },
    activeFilters: {
      immediate: true,
      handler(val) {
        this.$store.commit('ui/set:activeFilters', val);
      },
    },
  },

  methods: {
    async handleEditablePeriods({ index, projectId }) {
      this.item = await this.$store.dispatch(`projects/fetch`, projectId);

      this.editablePeriods = this.projectPeriods.filter(project => project.row === index + 1);
      this.editableEvents = this.projectEvents.filter(event => event.row === index + 1);
    },

    async updateProject(id) {
      this.editablePeriods.map(period => {
        const { start, end, type } = period;
        this.$set(this.item, `${type}StartAt`, this.weeks[start - 1].date);
        this.$set(this.item, `${type}EndAt`, this.weeks[end].date);
        this.$set(this.item, `${type}Duration`, Math.abs(start - end));
      });

      this.editableEvents.map(event => {
        const { start, type, webinarTime } = event;
        this.$set(
          this.item,
          type,
          type === 'webinarAt'
            ? this.webinar(this.weeks[start - 1].date, webinarTime)
            : this.weeks[start - 1].date,
        );
      });

      await this.$store.dispatch('planning/calendar/update:projectPeriods', {
        id,
        item: this.item,
      });

      await this.search();
    },

    async search() {
      this.loading = true;

      const projects = await this.$store.dispatch(`planning/calendar/fetch`, {
        params: this.preset.params,
        query: this.preset.searchTerm,
      });

      const splitIndex = projects.findIndex(project =>
        project.periods?.some(period => period.start && period.end),
      );

      this.determineRange(projects);
      this.determineWeeks(projects);

      this.projects = [
        ...projects.slice(splitIndex, projects.length),
        ...projects.slice(0, splitIndex),
      ];

      this.editablePeriods = [];
      this.editableEvents = [];
      this.loading = false;
    },

    determineRange(projects) {
      const startDatePeriodArray = projects.flatMap(project =>
        project.periods.filter(period => period.start).map(period => period.start),
      );
      const startDateEventArray = projects.flatMap(project =>
        project.events.filter(event => event.date).map(event => event.date),
      );

      const endDateArray = projects
        .flatMap(project => project.periods.filter(period => period.end).map(period => period.end))
        .sort();

      if (
        ![...startDatePeriodArray, ...startDateEventArray].length ||
        ![...endDateArray, ...startDateEventArray].length
      ) {
        this.range = this.initialRange();
        return;
      }

      const start = [...startDatePeriodArray, ...startDateEventArray].sort();

      const startDate = moment(start?.[0])
        .subtract(2, 'weeks')
        .format('YYYY-MM-DD');
      const endDate = moment(endDateArray?.[endDateArray.length - 1])
        .add(28, 'weeks')
        .format('YYYY-MM-DD');

      this.range = [this.setDateToMonday(startDate), this.setDateToMonday(endDate)];
    },

    determineWeeks() {
      const start = moment(this.range[0]);
      const end = moment(this.range[1]);
      const diff = end.diff(start, 'week') + 1;
      this.weeks = new Array(diff).fill(0).map((arr, index) => {
        const date = moment(this.range[0]).add(index, 'week');
        return {
          number: date.week(),
          date: date.format('YYYY-MM-DD'),
        };
      });
    },

    scrollToCurrentWeek() {
      const container = this.$refs.ganttContainer;
      const pixels = this.dateIndex(moment()) * 32 - 32;
      container.scrollTo({
        top: 0,
        left: pixels,
        behavior: 'smooth',
      });
      this.pos.left = pixels;
    },

    initialRange() {
      return [
        moment()
          .subtract(1, 'years')
          .format('YYYY-MM-DD'),
        moment()
          .add(1, 'years')
          .format('YYYY-MM-DD'),
      ];
    },

    yearColumns(year) {
      return {
        'grid-area': `auto / auto / auto / span ${
          this.weeks.map(week => week.date).filter(item => item.slice(0, 4) === year).length
        }`,
      };
    },

    monthColumns(month) {
      return {
        'grid-area': `auto / auto / auto / span ${
          this.weeks.map(week => week.date).filter(item => item.slice(0, 7) === month).length
        }`,
      };
    },

    plotPeriod(data) {
      return {
        'grid-row': data.row,
        'grid-column-start': data.start,
        'grid-column-end': data.end,
      };
    },

    plotEvent(data) {
      return {
        'grid-row': data.row,
        'grid-column': `${data.start} / span 1`,
      };
    },

    dateIndex(date) {
      const dateOnlyMonday = this.setDateToMonday(date);

      const index =
        this.weeks.findIndex(week => week.date === moment(dateOnlyMonday).format('YYYY-MM-DD')) + 1;

      return index;
    },

    setDateToMonday(date) {
      let weekNumber = moment(date).isoWeekday();

      if (weekNumber != 1) {
        date = moment(date)
          .subtract(weekNumber - 1, 'days')
          .format('YYYY-MM-DD');
      }
      return date;
    },

    getMonthName(date) {
      return moment(date)
        .locale(`${this.$store.state.ui.language.code}`)
        .format('MMMM');
    },

    webinar(date, webinarTime) {
      return moment(`${date}${webinarTime}`, 'YYYY-MM-DD:hh:mm').format('YYYY-MM-DD hh:mm');
    },

    setParam(key, val) {
      if (val === null) {
        this.$delete(this.lastUsed.params, key);
      } else {
        this.$set(this.lastUsed.params, key, val);
      }

      this.lastUsed = this.lastUsed;
    },

    mouseDragHandler(e) {
      if (!this.editablePeriods.length) {
        this.pos = {
          left: this.container.scrollLeft,
          top: this.container.scrollTop,
          x: e.clientX,
          y: e.clientY,
        };

        this.container.style.cursor = 'grabbing';
        this.container.style.userSelect = 'none';

        this.container.onmousemove = e => {
          const dx = e.clientX - this.pos.x;
          const dy = e.clientY - this.pos.y;

          this.container.scrollTop = this.pos.top - dy;
          this.container.scrollLeft = this.pos.left - dx;
        };

        this.container.onmouseup = () => {
          this.container.onmousemove = this.container.onmouseup = null;

          this.container.style.cursor = 'grab';
          this.container.style.removeProperty('user-select');
        };
      }
    },
  },

  computed: {
    projectPeriods() {
      const periodTypes = this.periods.flatMap(period => period.type);
      return this.projects?.flatMap((project, index) =>
        project.periods
          .filter(period => {
            return period.start && period.end && periodTypes.includes(period.type);
          })
          .map(period => ({
            row: index + 1,
            start: this.dateIndex(period.start),
            end: this.dateIndex(period.end) + 1,
            type: period.type,
            status: period.status,
          })),
      );
    },

    projectEvents() {
      const eventTypes = this.events.flatMap(event => event.type);
      return this.projects?.flatMap((project, index) =>
        project.events
          .filter(event => {
            return event.date && eventTypes.includes(event.type);
          })
          .map(event => ({
            row: index + 1,
            start: this.dateIndex(event.date),
            type: event.type,
            icon: this.events.find(e => e.type === event.type)?.icon,
            webinarTime: event.type === 'webinarAt' ? moment(event.date).format('hh:mm') : null,
          })),
      );
    },

    periodEditorArea() {
      if (this.editablePeriods.length) {
        return {
          'grid-area': `${this.editablePeriods[0].row} / 1 / auto / ${this.weeks.length + 1}`,
          display: 'grid',
          'grid-gap': '2px',
          'grid-template-columns': `repeat(${this.weeks.length}, 30px)`,
        };
      }

      return {};
    },

    columns() {
      return {
        display: 'grid',
        'grid-gap': '2px',
        'grid-template-columns': `repeat(${this.weeks.length}, 30px)`,
      };
    },

    rows() {
      return {
        display: 'grid',
        'grid-gap': '2px',
        'grid-template-rows': `repeat(${this.projects.length}, 30px)`,
      };
    },

    table() {
      return {
        display: 'grid',
        'grid-gap': '2px',
        'grid-template-columns': `repeat(${this.weeks.length}, 30px)`,
        'grid-template-rows': `repeat(${this.projects.length}, 30px)`,
      };
    },

    uniqueYears() {
      return [...new Set(this.weeks.map(week => week.date.slice(0, 4)))];
    },

    uniqueMonths() {
      return [...new Set(this.weeks.map(week => week.date.slice(0, 7)))];
    },

    todayIndicatorLine() {
      return {
        'grid-area': `1 / ${this.dateIndex(moment())} / 2 / span 1`,
      };
    },

    tableSettings() {
      return this.$store.getters[`planning/calendar/settings/get`];
    },

    preset() {
      return this.tableSettings.lastUsed;
    },

    lastSearch() {
      return this.tableSettings.lastSearch;
    },

    lastUsed: {
      get() {
        return this.tableSettings.lastUsed;
      },

      set(preset) {
        this.$store.commit(`planning/calendar/settings/set:lastUsed`, preset);
      },
    },

    activeFilters() {
      const params = _.pick(this.lastUsed.params, Object.values(this.filters));

      let filters = Object.keys(params).reduce((amount, key) => {
        const param = params[key];

        switch (true) {
          case param === undefined:
          case param === null:
          case param instanceof Array && param.length === 0:
            return amount;
          default:
            return amount + 1;
        }
      }, 0);

      if (this.lastUsed.searchTerm) {
        filters++;
      }

      return filters;
    },
  },
};
</script>
<style lang="scss">
$periods: (
  'screening': #80cbc4,
  'conversion': #ce93d8,
  'planning': #e6b635,
  'installation': #42c5fe,
);

@each $period, $color in $periods {
  .period-color-#{$period} {
    background-color: $color;
    border: 4px solid $color;
  }
  .period-status-not_started-#{$period} {
    opacity: 0.8;
    background-color: rgba($color, 0.2);
  }
  .period-status-started-#{$period} {
    opacity: 0.9;
    background: repeating-linear-gradient(
      45deg,
      $color,
      $color 5px,
      rgba($color, 0.2) 5px,
      rgba($color, 0.2) 10px
    );
  }
}

#container {
  cursor: grab;
  width: 100%;
  height: calc(100vh - 345px);
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  overflow: auto;
  border: 1px solid rgba(189, 189, 189, 0.9) !important;
}

#dates {
  grid-area: 1 / 2 / 2 / 3;
  position: sticky;
  top: 0;
  z-index: 4;
  background-image: linear-gradient(to left, rgba(189, 189, 189, 0.4) 6.25%, transparent 6.25%),
    linear-gradient(to top, rgba(189, 189, 189, 0.4) 6.25%, transparent 6.25%);
  background-size: 32px 32px;
  background-repeat: repeat;
  border-bottom: 3px solid rgba(189, 189, 189, 0.4) !important;
}

#column-headers {
  grid-area: 1 / 1 / 2 / 2;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 5;
  border-right: 3px solid rgba(189, 189, 189, 0.4) !important;
  border-bottom: 3px solid rgba(189, 189, 189, 0.4) !important;
}

#periods {
  grid-area: 2 / 2 / 3 / 3;
  position: relative;
  opacity: 1;
  background-image: linear-gradient(to left, rgba(189, 189, 189, 0.4) 6.25%, transparent 6.25%),
    linear-gradient(to top, rgba(189, 189, 189, 0.4) 6.25%, transparent 6.25%);
  background-size: 32px 32px;
  background-repeat: repeat;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block {
  min-height: 30px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: inherit;
}

.status {
  width: 45px;
  height: 18px;
}

.not-started {
  background: rgba(189, 189, 189, 0.2);
  -webkit-box-shadow: inset 0px 0px 0px 4px #bdbdbd;
  -moz-box-shadow: inset 0px 0px 0px 4px #bdbdbd;
  box-shadow: inset 0px 0px 0px 4px #bdbdbd;
}

.started {
  -webkit-box-shadow: inset 0px 0px 0px 3px #bdbdbd;
  -moz-box-shadow: inset 0px 0px 0px 3px #bdbdbd;
  box-shadow: inset 0px 0px 0px 3px #bdbdbd;
  opacity: 0.8;
  background: repeating-linear-gradient(
    45deg,
    #bdbdbd,
    #bdbdbd 5px,
    transparent 5px,
    transparent 10px
  );
}

.finished {
  background-color: #bdbdbd;
}

.todayIndicatorLine:before {
  content: '';
  background-color: rgba(172, 172, 172, 0.3);
  width: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.periods {
  height: 100%;
  z-index: 1;
}

.events {
  z-index: 2;
  border-radius: 3px;
  background-color: white;
  border: 2px solid #00838f !important;
  box-shadow: 0px 2px 7px 0px #00838f;
  position: relative;
  height: 25px;
}

.period-editor-area {
  background-image: linear-gradient(to left, rgba(172, 172, 172, 0.1) 6.25%, transparent 6.25%);
  background-size: 32px 32px;
  background-repeat: repeat;
  z-index: 3;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.periodsOverlay {
  grid-area: 2 / 2 / 3 / 3;
  opacity: 0.7;
  background-color: inherit;
  z-index: 2;
}
</style>
